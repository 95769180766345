import { Button, Divider, Stack, Typography } from '@mui/material'
import { ShoulderSlopeImage } from 'assets'

export function ShoulderSlope() {
  return (
    <Stack spacing={5} alignItems='center'>
      <Typography variant='h1'>Shoulder Slope</Typography>
      <Divider sx={{ width: 1 }} />
      <img src={ShoulderSlopeImage} alt='shoulder-slope-tool' />
      <Typography variant='h4'>Face The Client</Typography>
      <Typography variant='smallBody2' align='center'>
        Be sure to measure using the client's perspective of left and right.
      </Typography>
      <Button
        onClick={() => window?.ReactNativeWebView?.postMessage('shoulderSlope')}
        sx={{ borderRadius: 50, width: '60%' }}
      >
        Get Started
      </Button>
    </Stack>
  )
}
