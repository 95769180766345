import { type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'
import { Orders, action, loader } from './orders'

export const ordersRoutes: RouteObject = {
  path: 'orders',
  element: <Orders />,
  loader: loader,
  action: action,
  errorElement: <ErrorBoundary />,
}
