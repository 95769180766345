import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const priceListRoutes: RouteObject = {
  path: 'price-list',
  errorElement: <ErrorBoundary />,
  children: [
    { index: true, element: <Navigate replace to='fabrics' /> },
    {
      path: 'fabrics',
      async lazy() {
        const { FabricPriceList, loader } = await import('./fabrics')
        return { Component: FabricPriceList, loader }
      },
    },
    {
      path: 'options',
      async lazy() {
        const { OptionPriceList } = await import('./options')
        return { Component: OptionPriceList }
      },
    },
  ],
}
