import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
} from '@mui/material'
import { Assignment, FlipToBack, MoreVert, Person } from '@mui/icons-material'
import { useGlobalState } from '@trinity/components'

function BottomNav() {
  const location = useLocation()
  // grab the first 4 characters of the pathname to determine the base route
  const baseRoute = location.pathname.substring(1, 5)
  // find the index of the base route in the baseNavList
  const baseNavIndex = baseNavList.findIndex(item => item.startsWith(baseRoute))
  const [value, setValue] = useState(baseNavIndex)
  const navigate = useNavigate()

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(_event, newValue: number) => {
        setValue(newValue)
        navigate(baseNavList[newValue] ?? baseNavList[0])
      }}
      sx={{ position: 'fixed', bottom: 0, width: '100%', height: 100, boxShadow: 6, zIndex: 'tooltip' }}
    >
      <BottomNavigationAction
        label='Orders'
        icon={<Assignment />}
        sx={{ '&.Mui-selected': { color: 'common.black' } }}
      />
      <ControlledTooltip title={<NavPopover navList={navList.clients} />}>
        <BottomNavigationAction
          label='Clients'
          icon={<Person />}
          sx={{ '&.Mui-selected': { color: 'common.black' } }}
        />
      </ControlledTooltip>
      <ControlledTooltip title={<NavPopover navList={navList.fabrics} />}>
        <BottomNavigationAction
          label='Fabrics'
          icon={<FlipToBack />}
          sx={{ '&.Mui-selected': { color: 'common.black' } }}
        />
      </ControlledTooltip>
      <ControlledTooltip title={<NavPopover navList={navList.tools} />}>
        <BottomNavigationAction label='More' icon={<MoreVert />} sx={{ '&.Mui-selected': { color: 'common.black' } }} />
      </ControlledTooltip>
    </BottomNavigation>
  )
}

export default BottomNav

function ControlledTooltip({ children, ...props }: TooltipProps) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false)
      }, 4000)
      return () => clearTimeout(timer)
    }

    return undefined
  }, [open])

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <StyledTooltip onOpen={handleOpen} onClose={handleClose} open={open} {...props}>
      {children}
    </StyledTooltip>
  )
}

interface NavPopoverProps {
  navList: { title: string; link: string; permissions: string }[]
}

const NavPopover = ({ navList }: NavPopoverProps) => {
  const { user } = useGlobalState()
  const navigate = useNavigate()

  return (
    <Stack>
      {navList.map(item => (
        <Button
          key={item.title}
          variant='text'
          onClick={() => navigate(item.link)}
          sx={{
            color: 'common.white',
            alignSelf: 'flex-start',
            p: 0,
            display: user?.permissions?.nav?.includes(item.permissions) ? 'block' : 'none',
          }}
        >
          {item.title}
        </Button>
      ))}
    </Stack>
  )
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow placement='top' leaveTouchDelay={4000} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2.5),
    paddingRight: theme.spacing(4),
  },
}))

const baseNavList = ['orders', 'clients', 'fabric-explorer/collections', 'more'] as const

const navList = {
  clients: [
    { title: 'Add New Client', link: 'clients/new', permissions: 'can_view_clients' },
    { title: 'Client Insights', link: 'clients/insights', permissions: 'can_view_client_insights' },
  ],
  fabrics: [
    { title: 'Explore Collections', link: 'fabric-explorer/collections', permissions: 'can_explore_collections' },
    { title: 'Find A Fabric', link: 'fabric-explorer/fabrics', permissions: 'can_explore_collections' },
  ],
  tools: [
    { title: 'Model Library', link: 'library/models', permissions: 'can_view_library' },
    { title: 'Options Library', link: 'library/options', permissions: 'can_view_library' },
    // { title: 'Embroidery Info', link: '#' },
    { title: 'Digital Materials Pack', link: 'library/materials', permissions: 'can_view_library' },
    { title: 'Price List', link: 'price-list', permissions: 'can_view_price_list' },
  ],
}
