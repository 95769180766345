import { Navigate, Outlet, createBrowserRouter, useLoaderData } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { ErrorBoundary, GlobalContainer, TrinityWrapper } from '@trinity/components'
import { checkAuth, type UseLoaderData } from '@trinity/utils'
import { BottomNav, TopNav } from 'components'
import { clientRoutes } from './clients'
import { fabricsRoutes } from './fabric-explorer'
import { priceListRoutes } from './price-list'
import { ordersRoutes } from './orders'
import { moreRoutes } from './more'
import { libraryRoutes } from './library'
import { shoulderSlopeRoutes } from './shoulder-slope'
import { loader as logoutLoader } from './logout'
import { reportsRoutes } from './reports'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    loader,
    element: <Layout />,
    children: [
      { index: true, element: <Navigate replace to='orders' /> },
      ordersRoutes,
      clientRoutes,
      fabricsRoutes,
      priceListRoutes,
      moreRoutes,
      libraryRoutes,
      shoulderSlopeRoutes,
      reportsRoutes,
      {
        path: 'logout',
        loader: logoutLoader,
      },
      {
        path: '*',
        element: <div>Page Not Found</div>,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
])

async function loader() {
  const user = await checkAuth('dealer', '/logout')

  return user
}

function Layout() {
  const user = useLoaderData() as UseLoaderData<typeof loader>

  return (
    <TrinityWrapper appName='skiffle' user={user}>
      <nav>
        <TopNav />
        <BottomNav />
      </nav>
      <main>
        <GlobalContainer sx={{ mb: 15 }}>
          {/* <ScrollRestoration /> */}
          <Outlet />
        </GlobalContainer>
      </main>
    </TrinityWrapper>
  )
}
