import { type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const moreRoutes: RouteObject = {
  path: 'more',
  async lazy() {
    const { More } = await import('./More')
    return { Component: More }
  },
  errorElement: <ErrorBoundary />,
}
