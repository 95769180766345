import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { initSentry } from '@trinity/utils'
import App from './App'

const sentryDsn = 'https://d1f9c19c43edd2121cba060c9cfd7f68@o170893.ingest.sentry.io/4505964977389568'
const sentryRelease = `skiffle@${__APP_VERSION__}`
initSentry(sentryDsn, sentryRelease)

const rootEl = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootEl!)

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<div>Something went wrong</div>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
