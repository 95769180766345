import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu, MenuItem, Divider, Box, IconButton, Tooltip, Typography, Button, Avatar } from '@mui/material'
import { AccountCircle, ArrowBack } from '@mui/icons-material'
import { useGlobalState } from '@trinity/components'

function TopNav() {
  const { user } = useGlobalState()
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dealerInitials = `${user?.dealer?.firstName?.[0]}${user?.dealer?.lastName?.[0]}`
  //* Don't show back button on orders page
  const isOrdersPage = ['/', '/orders'].includes(location.pathname)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: isOrdersPage ? 'flex-end' : 'space-between',
          pr: 3,
          pt: 6,
        }}
      >
        {!isOrdersPage && (
          <Button
            variant='text'
            startIcon={<ArrowBack sx={{ color: 'neutral.main' }} />}
            onClick={() => navigate(-1)}
            sx={{ typography: 'smallBody1' }}
          >
            Back
          </Button>
        )}
        <Tooltip title='Account settings'>
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <AccountCircle sx={{ color: 'common.black' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            borderRadius: 4,
            width: 2 / 3,
            mt: 1.25,
            ml: 0.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 18,
              width: 10,
              height: 10,
              bgcolor: 'common.white',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{ sx: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end' } }}
      >
        <MenuItem>
          <Avatar sx={{ p: 3, bgcolor: 'primary.main' }}>{dealerInitials}</Avatar>
        </MenuItem>
        <MenuItem>
          <Typography noWrap variant='h5' sx={{ textDecoration: 'underline', maxWidth: 230 }}>
            {user?.dealer?.name}
          </Typography>
        </MenuItem>
        <Divider sx={{ width: 1, py: 1 }} />
        <MenuItem sx={{ my: 1 }}>
          <Button variant='outlined' onClick={() => navigate('/logout')}>
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </>
  )
}

export default TopNav
